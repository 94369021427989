<template>
    <div class="visitor-font flex min-h-screen flex-col items-center justify-between">
        <NuxtRouteAnnouncer />
        <PublicHeaderSection />
        <div class="flex w-full grow flex-col">
            <slot />
        </div>
        <ClientOnly>
            <LazyPublicCookiePreferences />
            <LazyPublicCookieConsent />
        </ClientOnly>
        <PublicFooterSection />
    </div>
</template>
