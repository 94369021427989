<template>
    <div class="w-full bg-base-200 text-base-content/80">
        <footer class="container flex flex-row flex-wrap items-center justify-between gap-x-2 gap-y-6 p-4 py-12">
            <div class="mx-auto flex flex-col items-center gap-2 xs:mx-0">
                <Logo class="size-12 text-base-content/60 sm:size-20" />
                <p class="mb-2 text-xs text-base-content/50 xs:hidden">
                    2022 © by Cina Delicious Cakes
                </p>
            </div>

            <div class="flex items-center justify-between gap-x-12">
                <div>
                    <p class="mb-2 hidden text-xs text-base-content/50 xs:block">
                        2022 © by<br>Cina Delicious Cakes
                    </p>
                    <nav>
                        <p class="mb-2 select-none text-sm font-bold">
                            Socials:
                        </p>
                        <div class="flex items-center justify-evenly gap-2">
                            <NuxtLink external
                                      href="https://www.facebook.com/CinaCDC"
                                      target="_blank"
                                      aria-label="Facebook"
                                      class="inline-block"
                                      rel="noopener">
                                <FacebookIcon />
                            </NuxtLink>
                            <NuxtLink external
                                      href="https://www.instagram.com/cina_delicious_cakes/"
                                      target="_blank"
                                      aria-label="Instagram"
                                      class="inline-block"
                                      rel="noopener">
                                <InstagramIcon />
                            </NuxtLink>
                            <NuxtLink external
                                      href="https://www.tiktok.com/@cinasdeliciouscakes"
                                      target="_blank"
                                      aria-label="TikTok"
                                      class="inline-block"
                                      rel="noopener">
                                <TiktokIcon />
                            </NuxtLink>
                        </div>
                    </nav>
                </div>

                <nav class="flex flex-col gap-y-2 text-center text-sm xs:text-left sm:text-base">
                    <NuxtLink :to="{ name: 'index' }">
                        Home Page
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'products' }">
                        Products
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'galleries' }">
                        Galleries
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'about' }">
                        About
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'faqs' }">
                        Frequently Asked Questions
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'contact' }">
                        Contact
                    </NuxtLink>
                    <NuxtLink :to="{ name: 'cookie-policy' }">
                        Cookie Policy
                    </NuxtLink>
                </nav>
            </div>

            <p class="w-full select-none text-center text-xs text-base-content/40 md:text-left">
                Made by <NuxtLink target="_blank" rel="noopener" href="https://nandor.kraszlan.dev">
                    Nandor Kraszlan
                </NuxtLink>
            </p>

            <div class="flex w-full flex-wrap items-center justify-center gap-2">
                <NuxtLink v-for="location in locations"
                          :key="location"
                          :to="{ name: 'slug-cakes', params: { slug: simpleSlug(location) } }"
                          class="text-xs opacity-40">
                    Cakes in {{ location }}
                </NuxtLink>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { locations } from '~/fixtures';

export default defineComponent({
    name: 'FooterSection',
    setup: () => ({
        locations,
        simpleSlug
    })
});
</script>
