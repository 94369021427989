<template>
    <header class="container mb-6 border-b border-primary/40 px-2 py-4">
        <div class="navbar">
            <div class="navbar-start items-center gap-x-3 sm:flex md:gap-x-6 lg:gap-x-12">
                <details ref="dropdown" class="dropdown sm:hidden">
                    <summary class="btn btn-circle btn-ghost">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             class="size-5"
                             fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M4 6h16M4 12h16M4 18h7" />
                        </svg>
                    </summary>
                    <ul tabindex="0"
                        class="menu-compact menu dropdown-content z-50 mt-3 w-52 rounded-box
                           bg-base-100/50 p-2 shadow backdrop-blur backdrop-brightness-110">
                        <li>
                            <NuxtLink :to="{ name: 'index' }" @click="$refs.dropdown.open = $route.name === 'index'">
                                Homepage
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="{ name: 'products' }"
                                      prefetch
                                      @click="$refs.dropdown.open = $route.name === 'products'">
                                Products
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="{ name: 'about' }" @click="$refs.dropdown.open = $route.name === 'about'">
                                About
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="{ name: 'contact' }"
                                      @click="$refs.dropdown.open = $route.name === 'contact'">
                                Contact&nbsp;Me
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="{ name: 'galleries' }"
                                      @click="$refs.dropdown.open = $route.name === 'galleries'">
                                Galleries
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="{ name: 'faqs' }" @click="$refs.dropdown.open = $route.name === 'faqs'">
                                FAQs
                            </NuxtLink>
                        </li>
                    </ul>
                </details>
            </div>
            <div class="navbar-center">
                <NuxtLink to="/" title="Homepage" aria-label="Homepage">
                    <Logo class="size-16 opacity-70 transition-colors" />
                </NuxtLink>
            </div>
            <div class="navbar-end" />
        </div>
        <nav class="hidden sm:navbar">
            <div class="navbar-center mx-auto flex w-full flex-row items-center justify-evenly gap-x-3 md:gap-x-6
                        lg:gap-x-12 xl:w-3/5">
                <NuxtLink :to="{ name: 'products' }">
                    Products
                </NuxtLink>
                <NuxtLink :to="{ name: 'galleries' }">
                    Galleries
                </NuxtLink>
                <NuxtLink :to="{ name: 'about' }">
                    About
                </NuxtLink>
                <NuxtLink :to="{ name: 'contact' }">
                    Contact&nbsp;Me
                </NuxtLink>
                <NuxtLink :to="{ name: 'faqs' }">
                    FAQs
                </NuxtLink>
            </div>
        </nav>
    </header>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
    name: 'HeaderSection',

    setup: () => {
        const dropdown = ref<HTMLDialogElement>();

        onClickOutside(dropdown, () => {
            if (!dropdown.value) return;

            dropdown.value.open = false;
        });

        return {
            dropdown
        };
    }
});
</script>
